import { ViewportListRef } from 'react-viewport-list';
import { MutableRefObject } from 'react';
import {
  MATCH_ACTION_SEND_TYPE_NAME,
  TEAM_DEFAULT_COLOR,
  UNKNOWN_PLAYER_NAME,
} from '@/constants';
import { SocketRequestPayload } from '@/contexts/scoring/createUseDispatchWithResponse';
import {
  FIXTURE_ACTION_FLAG_STATE,
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
} from '@/service/constants';
import {
  FixtureAction,
  FixtureConfig,
  FixtureSummary,
  PlayerGameStatistics,
  PlayerStatistics,
} from '@/service/types';
import { formatDate } from '@/utils/formatDate/formatDate';
import { UpdateActionFlagMsg } from '@/workers/scoring/types';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import { getActionSubTypeName } from '@/components/FixtureTabs/helpers/getActionSubTypeName';
import {
  getPlayer,
  getPlayerName,
  getPlayerShirtNumber,
} from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { DeletedActionId } from '@/contexts/scoring/types';
import { isPlayerUnknown } from '@/service/utils/actionConditions';
import {
  MarketPerActionOpenClose,
  MarketRelatedActionType,
} from '@/contexts/markets/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { konsole } from '@/utils/konsole';
import { ActionsTableCellDataset } from './ActionsTableCell';
import { SHEET_NAME, TABLE_CLASS_NAMES } from './constants';

type Column = {
  name: string;
  width: string;
  sortAttr?: string;
  secondarySortAttr?: string;
  tableType?: string[];
};

type Columns = {
  [key: string]: Column;
};

interface GenerateColumns {
  (hasSla?: boolean): Columns;
}

interface CreateScorersheetTeamStatsProps {
  isHomeTeam?: boolean;
  fixtureConfig?: FixtureConfig;
  fixtureSummary: FixtureSummary | null;
}

interface IsChangeableByMarketProps {
  actionTypeId: FixtureAction['fixtureActionTypeId'];
  marketRelatedActionTypes: MarketRelatedActionType[] | undefined;
  marketsPerAction: MarketPerActionOpenClose | undefined;
}

export const getTeamColor = (
  teamId: string | null | undefined,
  fixtureSummary: FixtureSummary | null | undefined,
) => {
  if (!teamId) return;
  if (!fixtureSummary) return TEAM_DEFAULT_COLOR;
  const team =
    fixtureSummary.homeTeam.id === teamId
      ? fixtureSummary.homeTeam
      : fixtureSummary.awayTeam.id === teamId
      ? fixtureSummary.awayTeam
      : null;
  if (!team) return TEAM_DEFAULT_COLOR;
  return team.teamColor || TEAM_DEFAULT_COLOR;
};

export const findRowInPath = (path: HTMLElement[]) =>
  path.find(
    (element) =>
      element.classList && element.classList.contains(TABLE_CLASS_NAMES.ROW),
  ) as HTMLDivElement | undefined;

export const getRowActionId = (rowEl: HTMLDivElement | undefined) => {
  return rowEl && rowEl.dataset.actionId;
};

export const findCellInPath = (path: HTMLElement[]) =>
  path.find(
    (element) =>
      element.classList && element.classList.contains(TABLE_CLASS_NAMES.CELL),
  ) as HTMLDivElement | undefined;

export const getCellDataset = (cellEl: HTMLDivElement | undefined) => {
  if (!cellEl) return;
  return cellEl.dataset as ActionsTableCellDataset;
};

export const parseCellDataValue = (
  value: string | number | boolean,
  type: ActionsTableCellDataset['filterValueType'],
) => {
  switch (type) {
    case 'boolean':
      return !!value;
    case 'number':
      return Number(value);
    case 'string':
      return value;
  }
};

export const findMatchingSubstitutionAction = (
  action: FixtureAction,
  actions: FixtureAction[],
  additionalMatchingFn?: (action: FixtureAction) => boolean,
): FixtureAction | null | undefined => {
  const { scorerId, scorerSeqNum } = action;
  const otherSeqNum =
    action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT
      ? scorerSeqNum + 1
      : action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.SUBSTITUTION_IN
      ? scorerSeqNum - 1
      : null;

  if (otherSeqNum === null) return null;

  return actions.find((searchAction) => {
    const baseResult =
      searchAction.scorerId === scorerId &&
      searchAction.scorerSeqNum === otherSeqNum;
    if (!additionalMatchingFn) return baseResult;
    return baseResult && additionalMatchingFn(searchAction);
  });
};

export const prepareFlagUpdatePayloads = (
  action: FixtureAction,
  actions: FixtureAction[],
): SocketRequestPayload<UpdateActionFlagMsg>[] | null => {
  const { flag } = action;
  if (flag === null) return null;
  const actionIds = [action.id];
  if (
    [
      FIXTURE_ACTION_TYPE.SUBSTITUTION_IN,
      FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT,
    ].includes(action.fixtureActionTypeId)
  ) {
    const matchingAction = findMatchingSubstitutionAction(
      action,
      actions,
      (action) => action.flag !== null,
    );
    matchingAction && actionIds.push(matchingAction.id);
  }

  const { UNCONFIRMEND, CONFIRMED } = FIXTURE_ACTION_FLAG_STATE;
  return actionIds.map((id) => ({
    fixtureActionId: id,
    fixtureId: action.fixtureId,
    state: flag.state === UNCONFIRMEND ? CONFIRMED : UNCONFIRMEND,
  }));
};

export const getRowBackgroundStyle = (teamColor?: string) => {
  if (!teamColor) return {};
  return {
    backgroundImage: `linear-gradient(90deg, rgba(${teamColor}, 1) 6px, rgba(${teamColor}, 0.15) 2px)`,
  };
};

export const createActionsSheet = (
  actions: FixtureAction[],
  fixtureSummary: FixtureSummary | null,
  fixtureConfig?: FixtureConfig,
) => {
  const mapActionData = (action: FixtureAction) => ({
    id: action.actionId,
    fixtureSeqNum: action.fixtureSeqNum,
    sendTypeId: action.sendTypeId,
    sendType: MATCH_ACTION_SEND_TYPE_NAME[action.sendTypeId],
    clockTime: action.clockTimeString,
    period: action.period,
    teamId: action.teamId,
    team: getTeamName(action.teamId, fixtureConfig, fixtureSummary),
    playerId: action.playerId,
    playerNumber: getPlayerShirtNumber(
      action.playerId,
      fixtureConfig,
      fixtureSummary,
    ),
    playerName: getPlayerName(action.playerId, fixtureConfig, fixtureSummary),
    actionTypeId: action.fixtureActionTypeId,
    actionType: getActionName(action.fixtureActionTypeId, fixtureConfig),
    actionSubTypeId: action.fixtureActionSubTypeId,
    actionSubType: getActionSubTypeName(
      action.fixtureActionSubTypeId,
      fixtureConfig,
    ),
    relatedFixtureActionId: action.relatedFixtureActionIds.join(', '),
    scorerId: action.scorerId,
    comment: action.fixtureActionComments?.[0]?.comment,
    metadata: JSON.stringify(action.metadata),
    timestamp: formatDate(action.timestamp),
  });
  return {
    name: SHEET_NAME.MATCH_DATA,
    data: actions.map(mapActionData),
  };
};

export const createFixtureSheet = (fixtureConfig?: FixtureConfig) => {
  const fixtureData = [
    {
      fixtureId: fixtureConfig?.fixture.id,
      fixtureName: fixtureConfig?.fixture.name,
      competitionId: fixtureConfig?.fixture.stage.season.competition.id,
      competitionName: fixtureConfig?.fixture.stage.season.competition.name,
    },
  ];
  return {
    name: SHEET_NAME.FIXTURE,
    data: fixtureData,
  };
};

export const createScorersheetTeamStats = ({
  isHomeTeam,
  fixtureConfig,
  fixtureSummary,
}: CreateScorersheetTeamStatsProps) => {
  const awayTeamId = fixtureSummary?.awayTeam.id;
  const teamsStats = fixtureSummary?.advancedSportStatistics?.teamsStatistics;

  const teamPlayers = teamsStats?.find(({ teamId }) => {
    return isHomeTeam ? teamId !== awayTeamId : teamId === awayTeamId;
  })?.playersStatistics;

  const playerStatistics = ({ playerId, gameStatistics }: PlayerStatistics) => {
    const { fieldGoalsMade, totalRebounds, assists } =
      gameStatistics as PlayerGameStatistics;
    const playerName = getPlayer(playerId, fixtureConfig, fixtureSummary!);
    const isUnknownPlayer = isPlayerUnknown(playerId);

    return {
      firstName: isUnknownPlayer ? UNKNOWN_PLAYER_NAME : playerName?.firstName,
      lastName: isUnknownPlayer ? UNKNOWN_PLAYER_NAME : playerName?.lastName,
      pts: fieldGoalsMade,
      rebounds: totalRebounds,
      assists: assists,
    };
  };

  return {
    name: isHomeTeam ? SHEET_NAME.HOME_TEAM_STATS : SHEET_NAME.AWAY_TEAM_STATS,
    data: teamPlayers?.map(playerStatistics) || [],
  };
};

export const scrollToTop = (
  listRef: MutableRefObject<ViewportListRef | null>,
) => {
  if (listRef.current === null) return;
  listRef.current.scrollToIndex({
    index: -1,
    alignToTop: false,
  });
};

export const findDeleteAction = (
  deletedActionIds: DeletedActionId[],
  action?: FixtureAction,
) =>
  deletedActionIds.find(
    (deletedAction) => action?.actionId === deletedAction?.actionId,
  );

export const isActionDeleted = (
  action: FixtureAction,
  deleteAction?: DeletedActionId,
) =>
  !!(
    deleteAction &&
    (action.sendTypeId !== MATCH_ACTION_SEND_TYPE.DELETED
      ? deleteAction.deletedSeqNum > action.fixtureSeqNum
      : deleteAction.updatedSeqNum > action.fixtureSeqNum)
  );

export const isActionUpdated = (
  action: FixtureAction,
  actions: FixtureAction[],
) => {
  if (!actions) {
    return false;
  }
  const currentActionSet = actions?.filter(
    (item) => item.actionId === action.actionId,
  );

  if (!currentActionSet.length) return false;

  return (
    currentActionSet[0].sendTypeId === MATCH_ACTION_SEND_TYPE.UPDATED &&
    currentActionSet[0].id !== action.id
  );
};

export const isChangeableByMarket = ({
  actionTypeId,
  marketRelatedActionTypes,
  marketsPerAction,
}: IsChangeableByMarketProps) => {
  if (!marketsPerAction) {
    return true;
  }

  const relatedSuffixes = marketRelatedActionTypes
    ?.filter(
      (actionType) => actionType.relatedActionTypes?.includes(actionTypeId),
    )
    .map((actionType) => actionType.marketActionSuffix);

  if (!relatedSuffixes || relatedSuffixes.length === 0) {
    return true;
  }

  return relatedSuffixes.some(
    (suffix) => !marketsPerAction[suffix]?.isMarketOpen,
  );
};

export const generateColumns: GenerateColumns = (hasSla) => {
  return {
    SEQ: {
      name: 'Seq Num',
      width: '8%',
      sortAttr: 'fixtureSeqNum',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    SEND_TYPE: {
      name: 'Send Type',
      width: '8%',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    CLOCK_TIME: {
      name: 'Clock Time',
      width: hasSla ? '9%' : '11%',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    PERIOD: {
      name: 'Period',
      width: '9%',
      sortAttr: 'period',
      secondarySortAttr: 'timestamp',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    ACTION_ICONS: {
      name: '',
      width: '3%',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    ACTION: {
      name: 'Action',
      width: hasSla ? '11%' : '14%',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    TEAM: {
      name: 'Team',
      width: hasSla ? '11%' : '14%',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    PLAYER: {
      name: 'Player',
      width: hasSla ? '11%' : '14%',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
    SCORE: {
      name: 'Score',
      width: '7%',
      tableType: [COMMON_STRING.MATCH],
    },
    POLICE_OFFICER: { name: '', width: '2%', tableType: [COMMON_STRING.SLA] },
    LATENCY: {
      name: 'Latency',
      width: '7%',
      tableType: [COMMON_STRING.SLA],
    },
    MISTAKE_COMMENT_ICON: {
      name: 'Mistake',
      width: '6%',
      tableType: [COMMON_STRING.SLA],
    },
    GDS: { name: 'GDS', width: '3%', tableType: [COMMON_STRING.SLA] },
    MORE: {
      name: 'More',
      width: '12%',
      tableType: [COMMON_STRING.SLA, COMMON_STRING.MATCH],
    },
  };
};

export const getColumnHeader = (isSla: boolean) => {
  const typeOfTable = isSla ? COMMON_STRING.SLA : COMMON_STRING.MATCH;
  return Object.values(generateColumns(isSla)).filter(
    (column) => column?.tableType?.includes(typeOfTable),
  );
};

export const getColumnsWidthTotal = (isSla: boolean): number => {
  const typeOfTable = isSla ? COMMON_STRING.SLA : COMMON_STRING.MATCH;
  return Object.values(generateColumns(isSla))
    .filter((column) => column.tableType?.includes(typeOfTable))
    .reduce((acc, column) => {
      const val = Number(column.width.slice(0, -1));
      return acc + val;
    }, 0);
};

const colMatchWidthsTotal = getColumnsWidthTotal(false);
const colSlaWidthsTotal = getColumnsWidthTotal(true);

if (colMatchWidthsTotal !== 100) {
  konsole.warn('Match data ActionsTable column widths', colMatchWidthsTotal);
}

if (colSlaWidthsTotal !== 100) {
  konsole.warn('Sla Breach ActionsTable column widths', colSlaWidthsTotal);
}
