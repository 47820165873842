import {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Stack, useTheme } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import { ScoringContext } from '@/contexts/scoring/context';
import { VisualNotificationsContext } from '@/contexts/visualNotifications/context';
import {
  findShowAndHideActions,
  generateInitialVisualNotificationsState,
  generateNewVisualNotificationsResults,
  generateSnackbarOptions,
  generateVisualNotificationsFilters,
  getPlayersLeft,
} from '@/contexts/visualNotifications/utils';
import {
  VISUAL_NOTIFICATIONS_PROPERTIES,
  VisualNotifications,
} from '@/contexts/visualNotifications/types';
import { SnackbarNoVarAction } from '@/components/SnackbarActions/SnackbarNoVarAction';
import { COMMON_STRING } from '@/constants/dictionary';
import { SnackbarAcknowledgeSupervisorCallAction } from '@/components/SnackbarActions/SnackbarAcknowledgeSupervisorCallAction';
import { RolePermissionsContext } from '@/contexts/rolePermissions/context';
import { hasPermissionsToElement } from '@/components/PermissionsChecker/utils';
import { SnackbarVarUnderwayAction } from '@/components/SnackbarActions/SnackbarVarUnderwayAction';
import { SnackbarStartMatchAction } from '@/components/SnackbarActions/SnackbarStartMatchAction';
import { SnackbarPlayStoppedAction } from '@/components/SnackbarActions/SnackbarPlayStoppedAction';
import { useStartTimer } from '@/utils/hooks/useStartTimer';
import { SnackbarPlayerLeft } from '@/components/SnackbarActions/SnackbarPlayerLeft';
import { getPlayerNameAndNumber } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { getSport } from '@/service/utils/getSport';

export const VisualNotificationsProvider = ({
  children,
}: PropsWithChildren) => {
  const {
    state: {
      fixtureId,
      fixtureActions,
      newFixtureActions,
      supervisorCall,
      fixtureSummary,
    },
    fixtureConfigState: { fixtureConfig },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const { showTimer, timerDuration, differenceBetweenPreStartAndRealTime } =
    useStartTimer({ fixtureId, fixtureSummary, fixtureActions });
  const { permissions } = useContext(RolePermissionsContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const sportId = getSport(fixtureConfig)?.id || 0;

  const visualNotificationsFilters = useMemo(
    () => generateVisualNotificationsFilters(sportId),
    [sportId],
  );

  const visualNotificationsReducer = (
    state: VisualNotifications,
    newValue: Partial<VisualNotifications>,
  ): VisualNotifications => {
    return { ...state, ...newValue };
  };

  const defaultState: VisualNotifications = {
    flagOnField: { showSnack: -1, hideSnack: -1, isActive: false },
    possibleVar: { showSnack: -1, hideSnack: -1, isActive: false },
    supervisorCall: { showSnack: -1, hideSnack: -1, isActive: false },
    varUnderway: { showSnack: -1, hideSnack: -1, isActive: false },
    startTime: { showSnack: -1, hideSnack: -1, isActive: false },
    playStopped: { showSnack: -1, hideSnack: -1, isActive: false },
    playerLeft: [],
  };

  const [visualNotificationsState, dispatchVisualNotifications] = useReducer(
    visualNotificationsReducer,
    defaultState,
  );

  const snackbarRef = useRef<Map<string | null, SnackbarKey>>(new Map());

  useEffect(() => {
    if (!fixtureActions?.actions || !!newFixtureActions.length) return;

    return dispatchVisualNotifications(
      generateInitialVisualNotificationsState(
        fixtureActions.actions,
        supervisorCall,
        visualNotificationsFilters,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureActions]);

  useEffect(() => {
    if (!fixtureActions) return;

    return dispatchVisualNotifications({
      playerLeft: getPlayersLeft({
        ...visualNotificationsFilters.PLAYER_LEFT,
        fixtureActions: fixtureActions.actions,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureActions?.actions]);

  useEffect(() => {
    if (!newFixtureActions.length) return;

    const newFlagOnFieldAction = findShowAndHideActions({
      ...visualNotificationsFilters.FLAG_ON_FIELD,
      fixtureActions: newFixtureActions,
    });

    const newPossibleVarAction = findShowAndHideActions({
      ...visualNotificationsFilters.POSSIBLE_VAR,
      fixtureActions: newFixtureActions,
    });

    const newVarUnderwayAction = findShowAndHideActions({
      ...visualNotificationsFilters.VAR_UNDERWAY,
      fixtureActions: newFixtureActions,
    });

    const newPlayStoppedAction = findShowAndHideActions({
      ...visualNotificationsFilters.PLAY_STOPPED,
      fixtureActions: newFixtureActions,
    });

    return dispatchVisualNotifications({
      flagOnField: generateNewVisualNotificationsResults(
        newFlagOnFieldAction,
        visualNotificationsState.flagOnField,
      ),
      possibleVar: generateNewVisualNotificationsResults(
        newPossibleVarAction,
        visualNotificationsState.possibleVar,
      ),
      varUnderway: generateNewVisualNotificationsResults(
        newVarUnderwayAction,
        visualNotificationsState.varUnderway,
      ),
      playStopped: generateNewVisualNotificationsResults(
        newPlayStoppedAction,
        visualNotificationsState.playStopped,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFixtureActions]);

  useEffect(() => {
    const isShowTimer =
      fixtureSummary?.currentPeriod !== fixtureSummary?.periods.length &&
      showTimer;
    dispatchVisualNotifications({
      startTime: { showSnack: -1, hideSnack: -1, isActive: isShowTimer },
    });
  }, [
    showTimer,
    fixtureSummary?.periods,
    fixtureSummary?.currentPeriod,
    differenceBetweenPreStartAndRealTime,
  ]);

  useEffect(() => {
    if (!Object.keys(visualNotificationsState).length) return;
    dispatchVisualNotifications({
      supervisorCall: {
        isActive: !supervisorCall ? false : !supervisorCall.acknowledged,
        showSnack: -1,
        hideSnack: -1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisorCall]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('flagOnField');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.flagOnField]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('possibleVar');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.possibleVar]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('supervisorCall');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.supervisorCall]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('startTime');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.startTime]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('varUnderway');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.varUnderway]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('playStopped');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.playStopped]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('playerLeft');

    snackbarRef.current.forEach((snackbarId, playerId) => {
      if (
        !visualNotificationsState.playerLeft.some(
          (playerLeft) => playerLeft.playerId === playerId,
        )
      ) {
        closeSnackbar(snackbarId);
        snackbarRef.current.delete(playerId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.playerLeft]);

  const actionsInfoSnackbars = (type: keyof VisualNotifications) => {
    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.FLAG_ON_FIELD) {
      if (
        !hasPermissionsToElement(
          'FixturePage.ScoreboardPanel.FlagOnField',
          permissions,
        )
      )
        return;
      return visualNotificationsState.flagOnField.isActive
        ? enqueueSnackbar(
            COMMON_STRING.FLAG_ON_FIELD,
            generateSnackbarOptions(type, theme, { action: () => null }),
          )
        : closeSnackbar(type);
    }

    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.POSSIBLE_VAR) {
      if (
        !hasPermissionsToElement(
          'FixturePage.ScoreboardPanel.PossibleVAR',
          permissions,
        )
      )
        return;

      return visualNotificationsState.possibleVar.isActive
        ? enqueueSnackbar(
            COMMON_STRING.POSSIBLE_VAR_ACTIVATED,
            generateSnackbarOptions(type, theme, {
              action: (snackbarId: SnackbarKey) => (
                <SnackbarNoVarAction
                  fixtureId={fixtureId}
                  snackbarId={snackbarId}
                  useDispatchWithResponse={useDispatchWithResponse}
                />
              ),
            }),
          )
        : closeSnackbar(type);
    }

    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.PLAYER_LEFT) {
      if (
        !hasPermissionsToElement(
          'FixturePage.ScoreboardPanel.PlayerLeft',
          permissions,
        )
      )
        return;

      !!visualNotificationsState.playerLeft.length &&
        visualNotificationsState.playerLeft.forEach((playerLeft) => {
          const teamName =
            playerLeft &&
            getTeamName(playerLeft.teamId, fixtureConfig, fixtureSummary);

          const player =
            playerLeft &&
            getPlayerNameAndNumber(
              playerLeft.playerId,
              fixtureConfig,
              fixtureSummary,
            );

          const message = `${player} (${teamName})`;

          const snackbarId = enqueueSnackbar(
            message,
            generateSnackbarOptions(type, theme, {
              key: `${playerLeft?.playerId}`,
              action: (snackbarId: SnackbarKey) => (
                <SnackbarPlayerLeft
                  teamId={playerLeft?.teamId}
                  playerId={playerLeft?.playerId}
                  fixtureId={fixtureId}
                  snackbarId={snackbarId}
                  useDispatchWithResponse={useDispatchWithResponse}
                />
              ),
            }),
          );
          snackbarRef.current.set(playerLeft?.playerId, snackbarId);
        });
    }

    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.START_TIME) {
      if (
        !hasPermissionsToElement(
          'FixturePage.ScoreboardPanel.StartTime',
          permissions,
        )
      )
        return;

      return visualNotificationsState.startTime.isActive
        ? enqueueSnackbar(
            !!fixtureSummary?.periods.length
              ? COMMON_STRING.MATCH_RESUMING_IN
              : COMMON_STRING.MATCH_STARTING_IN,
            generateSnackbarOptions(type, theme, {
              action: (snackbarId: SnackbarKey) => (
                <SnackbarStartMatchAction
                  fixtureSummary={fixtureSummary}
                  timerDuration={timerDuration as number}
                />
              ),
            }),
          )
        : closeSnackbar(type);
    }

    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.SUPERVISOR_CALL) {
      if (
        !hasPermissionsToElement(
          'FixturePage.ScoreboardPanel.SupervisorCall',
          permissions,
        )
      )
        return;
      return visualNotificationsState.supervisorCall.isActive &&
        supervisorCall &&
        !supervisorCall?.acknowledged
        ? enqueueSnackbar(
            <Stack flexDirection='row' gap={1} alignItems='center'>
              <CallIcon />
              {COMMON_STRING.CALL_TO} {supervisorCall?.scorerFullName}
            </Stack>,
            generateSnackbarOptions(type, theme, {
              action: (snackbarId: SnackbarKey) => (
                <SnackbarAcknowledgeSupervisorCallAction
                  fixtureId={fixtureId}
                  scorerId={supervisorCall?.scorerId}
                  snackbarId={snackbarId}
                  useDispatchWithResponse={useDispatchWithResponse}
                />
              ),
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
            }),
          )
        : closeSnackbar(type);
    }

    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.VAR_UNDERWAY) {
      if (
        !hasPermissionsToElement(
          'FixturePage.ScoreboardPanel.VARUnderway',
          permissions,
        )
      )
        return;
      return visualNotificationsState.varUnderway.isActive
        ? enqueueSnackbar(
            COMMON_STRING.VAR_UNDERWAY,
            generateSnackbarOptions(type, theme, {
              action: (snackbarId: SnackbarKey) => (
                <SnackbarVarUnderwayAction
                  fixtureId={fixtureId}
                  sportId={sportId}
                  useDispatchWithResponse={useDispatchWithResponse}
                  snackbarId={snackbarId}
                />
              ),
            }),
          )
        : closeSnackbar(type);
    }

    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.PLAY_STOPPED) {
      if (
        !hasPermissionsToElement(
          'FixturePage.ScoreboardPanel.PlayStopped',
          permissions,
        )
      )
        return;
      return visualNotificationsState.playStopped.isActive
        ? enqueueSnackbar(
            COMMON_STRING.PLAY_STOPPED,
            generateSnackbarOptions(type, theme, {
              action: (snackbarId: SnackbarKey) => (
                <SnackbarPlayStoppedAction
                  fixtureId={fixtureId}
                  useDispatchWithResponse={useDispatchWithResponse}
                  snackbarId={snackbarId}
                />
              ),
            }),
          )
        : closeSnackbar(type);
    }
  };

  return (
    <VisualNotificationsContext.Provider value={visualNotificationsState}>
      {children}
    </VisualNotificationsContext.Provider>
  );
};
