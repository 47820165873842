export const TEXT_FIELD_CONSTANCE = {
  CHARACTERS_LIMIT: 700,
  PLACEHOLDER: 'Describe other mistake...',
  CHARACTERS_REACHED: 'Characters limit reached',
};

export const MISTAKE_RULE = {
  NONE: 'None',
  ASSIGNED_TO_INCORRECT_TEAM: 'Assigned to incorrect team',
  ASSIGNED_TO_INCORRECT_PLAYER: 'Assigned to incorrect player',
  INCORRECT_WORKFLOW: 'Incorrect workflow',
  MISSED_ACTION_DELETED: 'Missed (action deleted)',
  MISSED: 'Missed',
  EXCESSIVE: 'Excessive',
  OTHER: 'Other',
} as const;

export const MISTAKES_SHORTCUTS = {
  [MISTAKE_RULE.ASSIGNED_TO_INCORRECT_TEAM]: 'IT',
  [MISTAKE_RULE.ASSIGNED_TO_INCORRECT_PLAYER]: 'IP',
  [MISTAKE_RULE.INCORRECT_WORKFLOW]: 'IW',
  [MISTAKE_RULE.MISSED_ACTION_DELETED]: 'AD',
  [MISTAKE_RULE.MISSED]: 'M',
  [MISTAKE_RULE.EXCESSIVE]: 'E',
  [MISTAKE_RULE.OTHER]: 'O',
};

export type MistakeRule = (typeof MISTAKE_RULE)[keyof typeof MISTAKE_RULE];

export const allowedCommentKeys = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Control',
  'Meta',
];
