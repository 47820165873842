import { alpha, createTheme, ThemeOptions } from '@mui/material/styles';
import {
  orange,
  grey,
  amber,
  yellow,
  red,
  purple,
  blue,
  green,
} from '@mui/material/colors';
import deepmerge from '@mui/utils/deepmerge';
import { ENVIRONMENT } from '@/constants/environment';
import { FixtureAction } from '@/service/types';
import {
  COLLECTION_RANK_IDS,
  MATCH_ACTION_SEND_TYPE,
} from '@/service/constants';
import { THEME_MODE } from './constants';

export type ActionSendTypeColor = Record<FixtureAction['sendTypeId'], string>;
export type ActionSendTypeColorOptions = Partial<ActionSendTypeColor>;

declare module '@mui/material/styles' {
  interface Palette {
    env: Partial<Record<ENVIRONMENT, string>>;
    rank: {
      [COLLECTION_RANK_IDS.BRONZE]: string;
      [COLLECTION_RANK_IDS.SILVER]: string;
      [COLLECTION_RANK_IDS.GOLD]: string;
      [COLLECTION_RANK_IDS.PLATINUM]: string;
    };
    stream?: {
      noStreamColor: string;
      noStreamContrastColor: string;
    };
    json: {
      main: string;
      blue: string;
      red: string;
      purple: string;
      background: string;
    };
    status: ActionSendTypeColor;
    tableBorderColor: string;
    tableHeaderColor: string;
    iconsColors: {
      borderColor: string;
      yellowCard: string;
      redCard: string;
    };
    defaultSoundButton: string;
    snackbarColors: {
      possibleVar: { backgroundColor: string };
      flagOnField: { backgroundColor: string };
      supervisorCall: { backgroundColor: string };
      marketClosed: { backgroundColor: string };
      marketOpened: { backgroundColor: string };
      varUnderway: { backgroundColor: string };
      startTime: { backgroundColor: string };
      playStopped: { backgroundColor: string };
      playerLeft: { backgroundColor: string };
    };
    svgColors: {
      main: string;
    };
    statsCompare: {
      backgroundgColor: string;
      borderColor: string;
    };
    slaMistakeBg: string;
    timeoutColor: string;
    addRating: {
      addRatingOverlayColor: string;
      addRatingOverlayHoverColor: string;
      addRatingOverlayBorderColor: string;
    };
  }

  interface Theme {
    palette: Palette;
  }

  interface PaletteOptions {
    env?: Partial<Record<ENVIRONMENT, string>>;
    rank?: {
      [COLLECTION_RANK_IDS.BRONZE]?: string;
      [COLLECTION_RANK_IDS.SILVER]?: string;
      [COLLECTION_RANK_IDS.GOLD]?: string;
      [COLLECTION_RANK_IDS.PLATINUM]?: string;
    };
    stream?: {
      noStreamColor: string;
      noStreamContrastColor: string;
    };
    json?: {
      main?: string;
      blue?: string;
      red?: string;
      purple?: string;
      background?: string;
    };
    status?: ActionSendTypeColorOptions;
    tableBorderColor?: string;
    tableHeaderColor?: string;
    iconsColors?: {
      borderColor: string;
      yellowCard: string;
      redCard: string;
    };
    svgColors?: {
      main: string;
    };
    statsCompare?: {
      backgroundgColor: string;
      borderColor: string;
    };
    slaMistakeBg?: string;
    defaultSoundButton?: string;
    timeoutColor?: string;
    snackbarColors?: {
      possibleVar: { backgroundColor: string };
      flagOnField: { backgroundColor: string };
      supervisorCall: { backgroundColor: string };
      marketClosed: { backgroundColor: string };
      marketOpened: { backgroundColor: string };
      varUnderway: { backgroundColor: string };
      startTime: { backgroundColor: string };
      playStopped: { backgroundColor: string };
      playerLeft: { backgroundColor: string };
    };
    addRating?: {
      addRatingOverlayColor: string;
      addRatingOverlayHoverColor: string;
      addRatingOverlayBorderColor: string;
    };
  }
  interface ThemeOptions {
    palette?: PaletteOptions;
  }
}

export const lightOnlyOptions: ThemeOptions = {
  palette: {
    mode: THEME_MODE.LIGHT,
    background: {
      default: grey[100],
    },
    json: {
      main: grey[700],
      blue: '#2196F3',
      red: '#D32F2F',
      purple: '#6A1B9A',
      background: grey[100],
    },
    stream: {
      noStreamColor: 'rgb(190,190,190)',
      noStreamContrastColor: 'rgba(255,255,255,0.1)',
    },
    status: {
      [MATCH_ACTION_SEND_TYPE.PENDING]: purple[600],
      [MATCH_ACTION_SEND_TYPE.UPDATED]: blue[600],
      [MATCH_ACTION_SEND_TYPE.CANCELLED]: amber[800],
      [MATCH_ACTION_SEND_TYPE.DELETED]: red[800],
      [MATCH_ACTION_SEND_TYPE.CONFIRMED]: green[700],
    },
    svgColors: {
      main: '#2E3238',
    },
    statsCompare: {
      backgroundgColor: '#FFCDCD',
      borderColor: '#FFFFFF',
    },
    slaMistakeBg: '#9E9E9E',
    tableBorderColor: grey[300],
    tableHeaderColor: grey[100],
    timeoutColor: grey[200],
    addRating: {
      addRatingOverlayColor: alpha('#FFDBCC', 0.9),
      addRatingOverlayHoverColor: alpha('#FFDBCC', 0.7),
      addRatingOverlayBorderColor: alpha('#FF4D00', 0.5),
    },
  },
  typography: {
    caption: {
      color: grey[700],
    },
  },
};

export const darkOnlyOptions: ThemeOptions = {
  palette: {
    mode: THEME_MODE.DARK,
    json: {
      main: grey[400],
      blue: '#42A5F5',
      red: '#E53935',
      purple: '#BA68C8',
      background: grey[900],
    },
    stream: {
      noStreamColor: 'transparent',
      noStreamContrastColor: 'rgba(255,255,255,0.1)',
    },
    status: {
      [MATCH_ACTION_SEND_TYPE.PENDING]: purple[200],
      [MATCH_ACTION_SEND_TYPE.UPDATED]: blue[300],
      [MATCH_ACTION_SEND_TYPE.CANCELLED]: amber[300],
      [MATCH_ACTION_SEND_TYPE.DELETED]: red['A200'],
      [MATCH_ACTION_SEND_TYPE.CONFIRMED]: green[300],
    },
    svgColors: {
      main: '#FFFFFF',
    },
    statsCompare: {
      backgroundgColor: '#842120',
      borderColor: '#2A2A2A',
    },
    slaMistakeBg: grey[400],
    tableBorderColor: grey[800],
    tableHeaderColor: grey[700],
    timeoutColor: grey[800],
    addRating: {
      addRatingOverlayColor: alpha('#3D3D3C', 0.9),
      addRatingOverlayHoverColor: alpha('#3D3D3C', 0.7),
      addRatingOverlayBorderColor: alpha('#FF4D00', 0.5),
    },
  },
  typography: {
    caption: {
      color: grey[400],
    },
  },
};

export const commonOptions = {
  components: {
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: grey[900],
          color: grey[100],
          backgroundImage: 'unset',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: orange[900],
          color: grey[100],
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          '&.Mui-selected': {
            border: '1px solid',
          },
        },
      },
    },
  },
  shape: { borderRadius: 5 },
  palette: {
    primary: {
      main: '#FF4D00',
      contrastText: grey[100],
    },
    secondary: {
      light: grey[700],
      main: grey[800],
      dark: grey[900],
      contrastText: grey[50],
    },
    error: {
      light: red[400],
      dark: red[900],
      main: red[600],
      contrastText: grey[50],
    },
    env: {
      [ENVIRONMENT.DEV]: '#FFD900',
      [ENVIRONMENT.SANDBOX]: '#41adbf',
      [ENVIRONMENT.STAGING]: '#83ED6B',
    },
    rank: {
      [COLLECTION_RANK_IDS.BRONZE]:
        'linear-gradient(180deg, #C98559 0%, #713B22 100%)',
      [COLLECTION_RANK_IDS.SILVER]:
        'linear-gradient(180deg, #C0C0C0 0%, #9F9F9F 100%)',
      [COLLECTION_RANK_IDS.GOLD]:
        'linear-gradient(180deg, #E2C57C 0%, #C79C58 100%)',
      [COLLECTION_RANK_IDS.PLATINUM]:
        'linear-gradient(180deg, #6ECED4 0%, #416A5C 100%)',
    },
    iconsColors: {
      borderColor: grey[400],
      yellowCard: yellow[600],
      redCard: '#DF0000',
    },
    warning: {
      main: amber[900],
      light: yellow['600'],
    },
    defaultSoundButton: grey[600],
    snackbarColors: {
      possibleVar: { backgroundColor: '#9354CC' },
      flagOnField: { backgroundColor: yellow[700] },
      supervisorCall: { backgroundColor: red[800] },
      marketClosed: { backgroundColor: orange[800] },
      marketOpened: { backgroundColor: green[800] },
      varUnderway: { backgroundColor: '#9354CC' },
      startTime: { backgroundColor: orange[700] },
      playStopped: { backgroundColor: orange[800] },
      playerLeft: { backgroundColor: orange[800] },
    },
  },
  transitions: {
    duration: {
      enteringScreen: 175,
      leavingScreen: 145,
    },
  },
  typography: {
    fontFamily: '"SF-UI", Arial',
    caption: {
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
      letterSpacing: '0.0833em',
    },
    subtitle2: {
      lineHeight: 1.4,
    },
  },
} as const satisfies ThemeOptions;

export const lightOptions = deepmerge(commonOptions, lightOnlyOptions);
export const darkOptions = deepmerge(commonOptions, darkOnlyOptions);

export const lightTheme = createTheme(lightOptions);
export const darkTheme = createTheme(darkOptions);

// @ts-ignore:next-line
window.theme = {
  light: lightTheme,
  dark: darkTheme,
};
